@import "src/styles/colors";
@import "src/styles/breakpoints";

.table-default {
  &__header {
    width: 100%;
    min-height: 64px;
    padding: 16px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 8px 32px;
    background: $white;
    box-shadow: inset 0px -1px 0px $border;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    transition: opacity .3s;

    &.sticky {
      position: sticky;
      top: 68px;
      z-index: 2;
    }

    &_loading {
      & > div {
        width: 100%;
      }
    }

    &.loading {
      opacity: .2;
      pointer-events: none;
    }

    .table-info {
      margin-right: 32px;
      display: flex;
      align-items: center;
    }

    .table-title-wrapper {
      display: flex;
      flex-direction: column;

      .title {
        color: $blackblue;
        font-weight: 600;
        display: flex;
        align-items: center;
      }
      .subtitle {
        margin-top: 10px;
      }
    }
    .info {
      margin-left: 8px;
    }
    .table-counter {
      margin-left: 12px;
      color: $lightgray;
      font-weight: 600;
    }
    .table-extra {
      display: flex;
      align-items: center;
      .table-default__header_sorting {
        padding: 0;
        flex-direction: row-reverse;
        color: $main;

        span {
          margin: 0;
        }
        svg {
          margin-left: 4px;
          margin-right: 0;
          height: 20px;
          width: 20px;
        }

        &, &:hover, &:active {
          background-color: transparent;
          svg path {
            fill: $lightgray;
          }
        }

        &.desc #Ascending {
          fill: $main;
        }
        &.asc #Descending {
          fill: $main;
        }
      }
    }
  }

  @media only screen and (max-width: $screen-tablet-max) and (min-width: $screen-tablet-min) {
    &__header {
      &.sticky {
        top: 136px;
      }
    }
  }

  @media only screen and (max-width: $screen-mobile-max) {
    &__header {
      .table-extra {
        width: 100%;
      }

      &.sticky {
        top: 0;
        position: initial;
      }
    }

    &-antd:has(.table-default__header) {
      position: sticky;
      top: 92px;
      z-index: 2;
    }
  }
}